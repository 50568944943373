import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Invoice/Return No', 'invoiceRef', true, 'desc', 'left'),
  new TableColumn('Creditor Name', 'creditorName', true, 'desc', 'left'),
  new TableColumn('Return Date', 'invoiceDate', true, 'desc', 'left'),
  new TableColumn('Balance', 'balance', true, 'asc', 'right'),
  new TableColumn('Cr Incl. GST', 'totalAmount', false, 'asc', 'right'),
  new TableColumn('GST', 'gstAmount', false, 'asc', 'right'),
  new TableColumn('Cr Amount', 'amount', false, 'asc', 'right'),
  new TableColumn('PO No', 'purchaseOrderNo', true, 'asc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]
export { Columns }