/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.OutstandingCreditFilter = (function() {

    /**
     * Namespace OutstandingCreditFilter.
     * @exports OutstandingCreditFilter
     * @namespace
     */
    var OutstandingCreditFilter = {};

    OutstandingCreditFilter.OutstandingCreditFilterModel = (function() {

        /**
         * Properties of an OutstandingCreditFilterModel.
         * @memberof OutstandingCreditFilter
         * @interface IOutstandingCreditFilterModel
         * @property {string|null} [InvoiceReturnNo] OutstandingCreditFilterModel InvoiceReturnNo
         * @property {string|null} [Vendor] OutstandingCreditFilterModel Vendor
         * @property {string|null} [PurchaseOrderNo] OutstandingCreditFilterModel PurchaseOrderNo
         * @property {string|null} [DateFrom] OutstandingCreditFilterModel DateFrom
         * @property {string|null} [DateTo] OutstandingCreditFilterModel DateTo
         * @property {string|null} [sortColumn] OutstandingCreditFilterModel sortColumn
         * @property {string|null} [sortOrder] OutstandingCreditFilterModel sortOrder
         * @property {number|null} [pageIndex] OutstandingCreditFilterModel pageIndex
         * @property {number|null} [pageSize] OutstandingCreditFilterModel pageSize
         */

        /**
         * Constructs a new OutstandingCreditFilterModel.
         * @memberof OutstandingCreditFilter
         * @classdesc Represents an OutstandingCreditFilterModel.
         * @implements IOutstandingCreditFilterModel
         * @constructor
         * @param {OutstandingCreditFilter.IOutstandingCreditFilterModel=} [p] Properties to set
         */
        function OutstandingCreditFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * OutstandingCreditFilterModel InvoiceReturnNo.
         * @member {string|null|undefined} InvoiceReturnNo
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        OutstandingCreditFilterModel.prototype.InvoiceReturnNo = null;

        /**
         * OutstandingCreditFilterModel Vendor.
         * @member {string|null|undefined} Vendor
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        OutstandingCreditFilterModel.prototype.Vendor = null;

        /**
         * OutstandingCreditFilterModel PurchaseOrderNo.
         * @member {string|null|undefined} PurchaseOrderNo
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        OutstandingCreditFilterModel.prototype.PurchaseOrderNo = null;

        /**
         * OutstandingCreditFilterModel DateFrom.
         * @member {string|null|undefined} DateFrom
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        OutstandingCreditFilterModel.prototype.DateFrom = null;

        /**
         * OutstandingCreditFilterModel DateTo.
         * @member {string|null|undefined} DateTo
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        OutstandingCreditFilterModel.prototype.DateTo = null;

        /**
         * OutstandingCreditFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        OutstandingCreditFilterModel.prototype.sortColumn = null;

        /**
         * OutstandingCreditFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        OutstandingCreditFilterModel.prototype.sortOrder = null;

        /**
         * OutstandingCreditFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        OutstandingCreditFilterModel.prototype.pageIndex = null;

        /**
         * OutstandingCreditFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        OutstandingCreditFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * OutstandingCreditFilterModel _InvoiceReturnNo.
         * @member {"InvoiceReturnNo"|undefined} _InvoiceReturnNo
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        Object.defineProperty(OutstandingCreditFilterModel.prototype, "_InvoiceReturnNo", {
            get: $util.oneOfGetter($oneOfFields = ["InvoiceReturnNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OutstandingCreditFilterModel _Vendor.
         * @member {"Vendor"|undefined} _Vendor
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        Object.defineProperty(OutstandingCreditFilterModel.prototype, "_Vendor", {
            get: $util.oneOfGetter($oneOfFields = ["Vendor"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OutstandingCreditFilterModel _PurchaseOrderNo.
         * @member {"PurchaseOrderNo"|undefined} _PurchaseOrderNo
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        Object.defineProperty(OutstandingCreditFilterModel.prototype, "_PurchaseOrderNo", {
            get: $util.oneOfGetter($oneOfFields = ["PurchaseOrderNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OutstandingCreditFilterModel _DateFrom.
         * @member {"DateFrom"|undefined} _DateFrom
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        Object.defineProperty(OutstandingCreditFilterModel.prototype, "_DateFrom", {
            get: $util.oneOfGetter($oneOfFields = ["DateFrom"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OutstandingCreditFilterModel _DateTo.
         * @member {"DateTo"|undefined} _DateTo
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        Object.defineProperty(OutstandingCreditFilterModel.prototype, "_DateTo", {
            get: $util.oneOfGetter($oneOfFields = ["DateTo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OutstandingCreditFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        Object.defineProperty(OutstandingCreditFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OutstandingCreditFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        Object.defineProperty(OutstandingCreditFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OutstandingCreditFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        Object.defineProperty(OutstandingCreditFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OutstandingCreditFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @instance
         */
        Object.defineProperty(OutstandingCreditFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified OutstandingCreditFilterModel message. Does not implicitly {@link OutstandingCreditFilter.OutstandingCreditFilterModel.verify|verify} messages.
         * @function encode
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @static
         * @param {OutstandingCreditFilter.IOutstandingCreditFilterModel} m OutstandingCreditFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutstandingCreditFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.InvoiceReturnNo != null && Object.hasOwnProperty.call(m, "InvoiceReturnNo"))
                w.uint32(10).string(m.InvoiceReturnNo);
            if (m.Vendor != null && Object.hasOwnProperty.call(m, "Vendor"))
                w.uint32(18).string(m.Vendor);
            if (m.PurchaseOrderNo != null && Object.hasOwnProperty.call(m, "PurchaseOrderNo"))
                w.uint32(26).string(m.PurchaseOrderNo);
            if (m.DateFrom != null && Object.hasOwnProperty.call(m, "DateFrom"))
                w.uint32(34).string(m.DateFrom);
            if (m.DateTo != null && Object.hasOwnProperty.call(m, "DateTo"))
                w.uint32(42).string(m.DateTo);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(66).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(74).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(80).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(88).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified OutstandingCreditFilterModel message, length delimited. Does not implicitly {@link OutstandingCreditFilter.OutstandingCreditFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @static
         * @param {OutstandingCreditFilter.IOutstandingCreditFilterModel} message OutstandingCreditFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutstandingCreditFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OutstandingCreditFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {OutstandingCreditFilter.OutstandingCreditFilterModel} OutstandingCreditFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutstandingCreditFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.OutstandingCreditFilter.OutstandingCreditFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.InvoiceReturnNo = r.string();
                    break;
                case 2:
                    m.Vendor = r.string();
                    break;
                case 3:
                    m.PurchaseOrderNo = r.string();
                    break;
                case 4:
                    m.DateFrom = r.string();
                    break;
                case 5:
                    m.DateTo = r.string();
                    break;
                case 8:
                    m.sortColumn = r.string();
                    break;
                case 9:
                    m.sortOrder = r.string();
                    break;
                case 10:
                    m.pageIndex = r.int32();
                    break;
                case 11:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes an OutstandingCreditFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof OutstandingCreditFilter.OutstandingCreditFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {OutstandingCreditFilter.OutstandingCreditFilterModel} OutstandingCreditFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutstandingCreditFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return OutstandingCreditFilterModel;
    })();

    return OutstandingCreditFilter;
})();

module.exports = $root;
