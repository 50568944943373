import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
// import ProtoBufApi from '@/services/protobuf-api'
// import AuditTrailApi from '@/services/audittrail-api'
// import { GlAccountTypes } from '@/enums'

export default {
  async getEntity(id, hash) {
    const url = !hash ? `/outstandingcredit/${id}` : `/outstandingcredit/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialiseOutstandingCreditFilter(filter)
    const url = `/outstandingcredit/list/${serialised}`
    const result = await FqApi.get(url)
    console.log(result)
    return result.data
  },
  serialiseOutstandingCreditFilter(filter) {
    const outstandingCreditProto = require('../../assets/proto/outstandingcredit/OutstandingCreditFilter.proto')
    const filterMessage = outstandingCreditProto.OutstandingCreditFilter.OutstandingCreditFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  clearPreviousSessionStorage(sessionId) {
    if (sessionId) {
      const regAll = new RegExp('outstandingcredit')
      const regCurrent = new RegExp(`${sessionId}`)
      Object.keys(sessionStorage)
        .filter(function(s) {
          return regAll.test(s) && !regCurrent.test(s)
        })
        .forEach(function(s) {
          // console.log(s)
          sessionStorage.removeItem(s)
        })
    }
  }
}