import { mapGetters, mapActions } from 'vuex'

//const moduleName = 'usedpartsinfo'

export default {
  computed: {
    // ...mapGetters(moduleName, {
    //   returnRoute: 'returnRoute',
    //   snapshots: 'snapshots',
    //   baseSnapshot: 'baseSnapshot',
    //   currentSnapshot: 'currentSnapshot',
    //   snapshotDiff: 'snapshotDiff'
    // }),
    ...mapGetters(['licenceExpired'])
  },
  methods: {
    //...mapActions(moduleName, ['getStoreItem', 'addStoreItem', 'saveSnapshot', 'clearSnapshots']),
    ...mapActions('printpreview', { setPrintPreviewReturnRoute: 'setReturnRoute', addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    ...mapActions('returnparts', { setReturnPartsReturnRoute: 'setReturnRoute', setReturnPartsReadOnlyView: 'setReadOnlyView' }),
    ...mapActions('printpreview', { addReportParameters: 'addParameters', removeReportParameters: 'removeParameters' })
  }
}
